import { Component, useState } from "react";
import { connect } from 'react-redux';
import axios from "axios";
import './FAQ.scss';
import { Button, Form } from "react-bootstrap";
import { getSellerHeaders, getSellerUrl } from "shared/http";
import { jiraKnowledge } from "shared/settings/apiconfig";

const FAQFn = () => {

    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchData, setSearchData] = useState('');
    const [hideContent, setHideContent] = useState(false);
    const [openIframeData, setOpenIframeData] = useState('');

    // pagination details
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // Function to handle search
    const handleSearch = () => {
        setLoading(true);
        axios.get(getSellerUrl(`${jiraKnowledge}${searchText}`), getSellerHeaders()).then(response => {
            setSearchData(response.data.data);
            setLoading(false);
        }).catch(error => {

        })
    };

    // Logic to slice data array based on current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = searchData?.values?.slice(indexOfFirstItem, indexOfLastItem);

    const handleInputChange = event => {
        setSearchText(event.target.value);
    };

    const checkTitle = (data) => {
        return data.replace(/@@@hl@@@/gi, `<b>`).replace(/@@@endhl@@@/gi, `</b>`)
    }

    const checkContent = (data) => {
        return data.replace(/@@@hl@@@/gi, `<b>`).replace(/@@@endhl@@@/gi, `</b>`)
    }

    const clearSearch = () => {
        setSearchText('');
        setSearchData('');
    }

    const handlePreviousClick = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextClick = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && e.target.name == "search") {
            handleSearch();
        }
    }

    const openIframeContent = (data) => {
        setHideContent(true);
        setOpenIframeData(data.content.iframeSrc);
    }
    const navigatetoBack = () => {
        setHideContent(false);
    }

    return (
        <div className='mt2' style={{ height: "100%", overflowY: 'scroll' }}>
            <div className='d-flex search-header-content p-10'>
                {   
                    hideContent ?
                        <div className="p-2">
                            <b style={{ backgroundColor: '#A50050', border: '1px solid #A50050', color: 'white' }} className="clickable p-2" onClick={() => { navigatetoBack() }}>
                                <i className="fas fa-chevron-left" /> Back
                            </b>
                        </div> :
                        <>
                            <div className="d-flex flex-column w-100">
                                <div className="d-flex flex-column h-100 font-weight-bold fs-20 mt-3 align-items-center justify-content-center">
                                    Search Frequently Asked Questions & Answers
                                </div>
                                <div className="d-flex mt-4 mobile-resp">
                                    <div className='search-box d-flex align-items-center'>
                                        <div className="input-group">
                                            <Form.Control className="search-input-box" type="text" id="search" aria-label='Search by text' name="search" placeholder='Search by name' onChange={handleInputChange} onKeyDown={handleKeyDown} value={searchText} />
                                            <span className="input-group-append align-items-center clickable" onClick={handleSearch}>
                                                <i className="fa fa-search"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mobile-btn-resp">
                                        <Button className="btn-secondary faq-btn" style={{ marginLeft: "20px" }} onClick={handleSearch} disabled={!searchText}>Search</Button>
                                        <Button className="clear-btn faq-btn btn-resp" style={{ marginLeft: "20px" }} onClick={clearSearch}>Clear All</Button>
                                    </div>
                                </div>     
                            </div>
                        </>
                }
            </div>

            {currentItems && !loading && !hideContent ?
                <div className='d-flex flex-column fqa-answer-head' name="content-view">
                    <div className='pt-3 fqa-answer-body'>
                        {
                            currentItems?.map((data, index) => {
                                return (
                                    <div key={index} className='p-3'>
                                        <tr className='d-flex flex-column'>
                                            <td style={{ fontSize: "20px", color: '#00263e' }} dangerouslySetInnerHTML={{ __html: checkTitle(data.title) }}></td>
                                            <td style={{ padding: "5px", color: '#A50050', cursor: "pointer" }} dangerouslySetInnerHTML={{ __html: checkContent(data.excerpt) }} onClick={() => { openIframeContent(data); }}></td>
                                        </tr>
                                    </div>
                                )
                            })
                        }
                    </div>

                    {/* <div className="d-flex flex-column flex-sm-row align-items-center justify-content-sm-between py-4" style={{ width: "70%" }}>
                            <Button className="btn-secondary" onClick={handlePreviousClick} disabled={currentPage === 1} >
                            Previous
                            </Button>
                            <Button className="btn-secondary" onClick={handleNextClick} disabled={indexOfLastItem >= searchData?.values?.length}>
                            Next
                            </Button>
                    </div> */}
                </div> : 
                    loading ?
                        <div className="loader d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
                            <img src={`${process.env.PUBLIC_URL}/static/icons/table/option_1.gif`} alt="default" height="100px" width="100px" />
                        </div> : 
                        hideContent ?
                            <div className='d-flex flex-column fqa-answer-head'>
                                <div className='pt-3 fqa-answer-body' id="content-view">
                                    <iframe className="iframe-border" src={`${openIframeData}`} width="100%" height="900"></iframe>
                                </div>
                            </div> :
                            <div className="loader d-flex align-items-center justify-content-center" style={{ height: '100%', fontWeight: "bold", fontSize: '20px' }}>
                                Search by text to view FAQ
                            </div>
            }
        </div>
    );
};

class FAQ extends Component {
    constructor(props) {
        super(props);
        this.buyrId = this.props.user.id
    }

    componentDidMount() {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', this.onBack);
    }

    onBack = (e) => {
        e.preventDefault();
        window.history.pushState(null, null, window.location.pathname);
    }

    render() {
        return <FAQFn {...this.props} />
    }
}

export default connect(state => state)(FAQ);
